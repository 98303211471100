import styled from "styled-components";

export const Main = styled.main`
  padding: 5rem 1.25rem 1.25rem;

  @media (min-width: 1290px) {
    max-width: 1290px;
    margin: 3rem auto;
  }
`;

export const ErrorTitle = styled.h1`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
`;

export const BackLink = styled.a`
  color: inherit;
  font-weight: 600;
`;
