import styled from "styled-components";

export const Main = styled.main`
  padding: 5rem 1.25rem 1.25rem;

  @media (min-width: 1290px) {
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-columns: 1fr 1fr 0.25fr;
    grid-column-gap: 4rem;
    margin-top: 1rem;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const PaymentStep = styled.div`
  display: grid;
  place-self: center;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 0.75rem;
  align-items: center;
  justify-items: center;
  font-size: calc(1vw + 1.25rem);

  h1 {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
  }
`;
