import fr from "react-phone-number-input/locale/fr";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputTag from "react-phone-number-input/react-hook-form";

import "react-phone-number-input/style.css";

function PhoneInput({ name, control, errors, value, colors }) {
  return (
    <PhoneInputTag
      control={control}
      rules={{
        required: {
          value: true,
        },
        validate: isValidPhoneNumber,
      }}
      errors={errors}
      international
      countries={["FR", "BE", "LU", "CH"]}
      defaultCountry="FR"
      countryCallingCodeEditable={false}
      labels={fr}
      name={name}
      value={value || ""}
      colors={colors}
    />
  );
}

export default PhoneInput;
