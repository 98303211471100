import {
  MainHeader,
  Wrapper,
  Logo,
  CustomLogo,
  Title,
} from "./styles/Header.style";

import { useTheme } from "./ThemeProvider";

function Header() {
  const theme = useTheme();
  return (
    <MainHeader headerBg={theme?.header_bg || "#fff"}>
      <Wrapper>
        {theme && <CustomLogo src={theme?.logoPath} alt="" />}
        {!theme && <Title>{theme?.title}</Title>}
        {!theme && (
          <>
            <Logo
              color="#FF005D"
              width="24"
              height="16"
              viewBox="0 0 24 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Accueil"
              role="img"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="logo-msds" fill="currentColor" fillRule="nonzero">
                  <path
                    d="M23.6343816,8.57777778 C23.636638,5.39983778 22.2942108,2.36259752 19.9256017,0.186666667 L19.9983229,3.74222222 C18.1680186,1.97036333 15.6625145,1.02903536 13.0898113,1.14666667 C10.2445954,1.14666667 8.86289308,2.14222222 8.86289308,3.30666667 C8.86289308,4.73777778 10.4991195,5.36888889 14.2533501,6.46222222 C19.3983732,8 21.8163522,10.0266667 21.8163522,13.1822222 C21.8175352,13.9621586 21.6437701,14.7328577 21.307304,15.44 C22.8188374,13.4575156 23.6350514,11.0506159 23.6343816,8.57777778 L23.6343816,8.57777778 Z"
                    id="Path"
                  />
                  <path
                    d="M2.72704403,12.6666667 C3.6360587,13.5555556 7.65390356,15.6266667 10.4536688,15.6266667 C13.4806876,15.6266667 15.0805535,14.6577778 15.0805535,13.2 C15.0805535,11.7422222 13.6988512,11.0044444 10.1991447,10.0355556 C5.36318658,8.73777778 2.20890566,6.68444444 2.20890566,3.42222222 C2.21495154,2.163935 2.68266623,0.949566984 3.52697694,0 C-0.837351928,4.20990879 -1.18472722,10.9842951 2.72704403,15.6 L2.72704403,12.6666667 Z"
                    id="Path"
                  />
                </g>
              </g>
            </Logo>
            <Title>Ma Salle de Sport</Title>
          </>
        )}
      </Wrapper>
    </MainHeader>
  );
}

export default Header;
