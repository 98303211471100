import React, { useState, useEffect, createContext, useContext } from "react";
import { useLocation } from "react-router";

import Error from "./Error";
import ClubSelector from "./ClubSelector";
import ApiSite from "./ApiSite";
import Loader from "./Loader";

const Context = createContext();

export const useCurrentClub = () => useContext(Context);
export default CurrentClub;

function CurrentClub({ clubId, children }) {
  const [currentClub, currentClubSet] = useState(false);
  const [clubs, clubsSet] = useState(false);
  const location = useLocation();

  useEffect(() => {
    currentClubSet(false);
    const query = new URLSearchParams(location.search);
    const params = {};

    if( clubId ) {
      params["place.id"] = clubId;
    }

    if (query.get("franchising.id")) {
      params["franchising.id"] = query.get("franchising.id");
    }
    if (query.get("places.id")) {
      params["places.id"] = query.get("places.id");
    }
    ApiSite.passSearch(params)
      .then((places) => {
        if (!places.length) {
          return currentClubSet("BAD_SUBSCRIPTION");
        }
        places.forEach((place) => {
          place.shop.abo.forEach((a) => (a.type = "abo"));
          place.shop.pass.forEach((a) => (a.type = "pass"));
        });
        currentClubSet(places.find((p) => p.id === +clubId));

        clubsSet(places);
      })
      .catch((e) => {
        if (e.restCode === "resourceNotFound") {
          return currentClubSet("resourceNotFound");
        }
        console.error(e);
        currentClubSet("error");
      });
  }, [clubId, location.search]);

  if (currentClub === false) return <Loader />;
  if (currentClub === undefined) {
    return (
      <Context.Provider value={[currentClub, clubs]}>
        <ClubSelector clubs={clubs} />;
      </Context.Provider>
    );
  }
  if (currentClub === "resourceNotFound")
    return <Error message="Ce club n'existe pas" />;
  if (currentClub === "error")
    return <Error message="Une erreur est survenue" />;
  if (currentClub === "BAD_SUBSCRIPTION")
    return <Error message="Ce club ne propose aucune offre" />;
  return (
    <Context.Provider value={[currentClub, clubs]}>{children}</Context.Provider>
  );
}
