import styled from "styled-components";

export const Main = styled.main`
  padding: 5rem 1.25rem 1.25rem;

  @media (min-width: 1290px) {
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CgvTitle = styled.h1`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;

  @media (min-width: 1290px) {
    font-size: 2.5rem;
  }
`;

export const SectionTitle = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2.5rem;
`;

export const ArticleTitle = styled.div`
  margin: 1rem 0;
  line-height: 1.2;

  ul {
    padding-left: 1rem;
  }

  li {
    list-style-type: decimal;
    margin: 0.5rem 0;
  }
`;
