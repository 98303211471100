import { forwardRef } from "react";
import { get } from "react-hook-form";

import { StyledInput } from "./styles/Form.style";

function Input({ errors, name, ...props }, ref) {
  const error = get(errors, name);

  return <StyledInput ref={ref} name={name} errors={error} {...props} />;
}

export default forwardRef(Input);
