import styled from "styled-components";
import { Link } from "react-router-dom";

export const ChoicesWrapper = styled.section`
  margin: 5rem auto 0;

  img {
    height: auto;
    max-width: 100%;
    display: block;
  }

  @media (max-width: 1290px) {
    article {
      margin-bottom: 3rem;
    }

    img {
      margin-bottom: 1rem;
      border-radius: 1rem;
    }
  }

  @media (min-width: 1290px) {
    article {
      display: grid;
      grid-template-columns: 1fr 4fr;
      gap: 1rem;
    }

    img {
      border-radius: 50%;
      height: 8rem;
      width: 8rem;
      object-fit: cover;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 1290px;
    gap: 4rem;
  }
`

export const ChoicesContainer = styled.section`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 0.5rem;
  margin-bottom: 2rem;

  @media (min-width: 1290px) {
    margin-bottom: 0;
  }
`;

export const ClubName = styled.h1`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #171717;

  @media (min-width: 1290px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
`;

export const ClubAddress = styled.address`
  font-size: 0.875rem;
  color: #171717;

  a {
    display: block;
    color: inherit;
    font-weight: 600;
    text-decoration: none;
  }

  @media (min-width: 1290px) {
    line-height: 1.5;
  }
`;

export const SkewSeparator = styled.hr`
  width: 3rem;
  height: 0.5rem;
  margin: 1.5rem 0;
  background-color: ${({ color }) => color};
  transform: skew(-24deg);
  border: none;
`;

export const OfferTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 600;
  margin-top: ${({ margin }) => (margin ? "3rem" : "")};

  span {
    font-weight: normal;
  }
`;

export const NoChoiceSelected = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const HomeLink = styled.a`
  color: inherit;
  font-weight: 600;
`;

export const OptionsWrapper = styled.article`
  margin-top: 1rem;

  h3 {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
`;

export const SingleOption = styled.article`
  display: grid;
  grid-template-columns: 1fr auto 1fr 10fr;
  padding: 1rem 0;
  align-items: center;
  color: ${({ selected, theme }) =>
    selected ? theme.primary : theme.secondary};
  transition: all 0.3s ease-in-out;
`;

export const OptionPrice = styled.p`
  justify-self: end;
  font-weight: 600;
`;

export const OptionSelect = styled(Link)`
  font-size: 0;
  height: 1rem;
  width: 1rem;
  border: 2px solid
    ${({ selected, theme }) => (selected ? theme.primary : theme.secondary)};
  display: block;
  margin-right: 1rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:after {
    content: "";
    height: 60%;
    width: 60%;
    background-color: ${({ selected, theme }) =>
      selected ? theme.primary : ""};
  }
`;

export const OptionInfos = styled.p`
  display: ${({ openInfos }) => (openInfos ? "block" : "none")};
  color: ${({ color }) => color};
  line-height: 1.2;
  font-size: 0.875rem;
  opacity: 0.6;
`;

export const OptionsMoreInfos = styled.button`
  border: none;
  background-color: transparent;
  appearance: none;
  display: flex;
  align-items: center;
`;
