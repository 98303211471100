import Header from "./Header";
import Footer from "./Footer";
import { SkewSeparator } from "./styles/Choices.style";
import { Main, CgvTitle, SectionTitle, ArticleTitle } from "./styles/Cgv.style";

export default function Cgv() {
  return (
    <>
      <Header />
      <Main>
        <CGV />
      </Main>
      <Footer />
    </>
  );
}

const Title = (props) => {
  return (
    <SectionTitle>
      Article {props.number}. {props.title}
    </SectionTitle>
  );
};

const SectionNumber = (props) => {
  return <span>{props.number}.&nbsp;&nbsp;</span>;
};
const CGV = () => {
  let a = 1;
  let s = 1;

  return (
    <div className="cgvPage">
      <CgvTitle>Conditions générales de vente</CgvTitle>
      <SkewSeparator color="#ff005d" />
      <article>
        <Title number={a++} title="Préambule" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le présent site internet www.masalledesport.com est édité et détenu
            par E-Audiences SAS, 60, avenue Charles de Gaulle, enregistrée au
            RCS de Nanterre sous le numéro 497 814 640, dont le n° de TVA
            intracommunautaire est FR 19 497 814 640 (ci-après dénommé « MSDS »)
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le site internet permet de trouver, comparer, réserver et acheter
            des activités sportives
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toute commande d’une activité sportive suppose la consultation, la
            compréhension et l’acceptation préalables des présentes conditions
            générales de vente.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client dispose de la faculté de sauvegarder ou d’imprimer les
            présentes en utilisant les fonctionnalités standards de son
            navigateur ou de son ordinateur.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client déclare :
            <ul>
              <li>
                disposer de la compétence technique pour utiliser le présent
                site ;
              </li>
              <li>
                avoir pleine capacité juridique pour s’engager contractuellement
                ;
              </li>
              <li>
                être informé que son engagement ne nécessite pas de signature
                manuscrite ou électronique.
              </li>
            </ul>
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            L’acceptation irrévocable des présentes conditions générales de
            vente par le client se fait en cliquant sur « J'accepte les
            conditions générales de vente ».
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            La commande est validée par le mécanisme du double clic.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client déclare que la commande des activités est sans rapport
            direct avec son activité professionnelle, leur commande étant
            exclusivement destinée à une utilisation personnelle privée et non
            professionnelle de sa part.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Définitions" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les termes ci-dessous auront, pour les parties, les significations
            suivantes :
            <ul>
              <li>
                « activité » : désigne l’activité ou les activité(s) sportive(s)
                commercialisée(s) sur le site web par MSDS et délivrée(s) par le
                partenaire. Les activités peuvent être proposées à l’unité, au
                forfait ou dans le cadre d’un abonnement ;
              </li>
              <li>
                « confirmation d’achat » : désigne le document contractuel
                identifiant notamment les activités commandées auprès de MSDS
                par le client, leur prix, la date de l’achat (.) et adressé par
                e-mail au client une fois la commande réalisée ;
              </li>
              <li>
                « client » : désigne la personne physique ayant ouvert un compte
                client ;
              </li>
              <li>
                «consommateur » : toute personne physique qui, dans le présent
                contrat, agit à des fins qui n'entrent pas dans le cadre de son
                activité professionnelle ;
              </li>
              <li>
                « contrat » : ensemble des documents contractuels acceptés par
                le client préalablement à toute commande comprenant les
                présentes conditions générales de vente et la confirmation
                d’achat ;
              </li>
              <li>
                « double clic » : le clic est la formulation électronique du «
                oui » ou de l’acceptation. Dans le cadre des présentes, le
                double clic s’entend d’un premier clic sur « Poursuivez votre
                réservation » matérialisant la vérification de la commande par
                le client et d’un second clic sur «Validez» (= clic postérieur à
                la vérification du détail, du prix de la commande et à la
                correction des éventuelles erreurs par le client) qui exprime
                l’acceptation et permet donc la confirmation de la commande par
                le client.
              </li>
              <li>
                « partenaire » : désigne la société dispensant aux clients les
                activités achetées sur le site web et ayant conclu un contrat de
                partenariat avec MSDS ;
              </li>
              <li>
                « site web » : désigne la plateforme de référencement,
                réservation et de vente en ligne des activités, accessible sur
                www.masalledesport.com ;
              </li>
              <li>
                « utilisateur » ou « visiteur » : désigne la personne naviguant
                sur le site web et/ou qui l’utilise, autre que le client ;
              </li>
              <li>
                « partie » et « parties » : désigne(nt) l’utilisateur ou le
                client ou / et MSDS.
              </li>
            </ul>
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Objet" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les présentes conditions générales de vente visent à définir les
            droits et obligations des parties dans le cadre de la
            commercialisation des activités sur le site web par MSDS.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Elles sont irrévocablement acceptées par le client avant toute
            commande.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Elles règlent toutes les étapes nécessaires à la passation de la
            commande et à son suivi entre les parties contractantes.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Documents contractuels" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les documents contractuels liant les parties sont composés des
            documents suivants selon la hiérarchie suivante :
            <ul>
              <li>la confirmation d’achat ;</li>
              <li>les présentes conditions générales de vente.</li>
            </ul>
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            En cas de contradiction entre des documents de nature différente ou
            de rang différent, il est expressément convenu entre les parties que
            les dispositions contenues dans le document de rang supérieur
            prévaudront pour les obligations se trouvant en conflit
            d’interprétation. En cas de contradiction entre les termes des
            documents de même ordre, les derniers documents en date prévaudront
            sur les autres.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Nonobstant les règles d’interprétation des contrats définies dans le
            Code civil, il sera fait application de critères de rang selon les
            principes suivants :
            <ul>
              <li>obligation par obligation ;</li>
              <li>ou à défaut alinéa par alinéa ;</li>
            </ul>
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title
          number={a++}
          title="Opposabilité des conditions générales de vente"
        />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            La version des conditions générales de vente opposable au client est
            celle acceptée par celui-ci au moment de la validation de sa
            commande.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les différentes versions des conditions générales de ventes sont
            archivées selon les modalités suivantes : archivage réalisé sous
            formats papier, PDF et Word sur un serveur sécurisé et délocalisé.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client peut accéder aux conditions générales de vente archivées
            en en faisant la demande par courrier électronique à l’adresse
            contact@masalledesport.com .
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les conditions générales de vente sont susceptibles d’être modifiées
            à tout moment.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Elles s’appliquent alors aux commandes passées postérieurement à
            leur mise en ligne.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les présentes conditions générales de vente sont rédigées en
            français. (En cas de difficulté d'interprétation résultant d'une
            contradiction entre la version française et les versions des
            présentes conditions générales de vente rédigées dans d'autres
            langues, seule la version française sera prise en compte.)
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Accès au site web" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            L’accès au site web est libre et gratuit à tout utilisateur
            disposant d'un accès à internet. Tous les coûts afférents à l'accès,
            que ce soit les frais matériels, logiciels ou d'accès à internet
            sont exclusivement à la charge de l'utilisateur. Il est seul
            responsable du bon fonctionnement de son équipement informatique
            ainsi que de son accès à internet.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le site web est accessible 24h/24 et 7 jours/7.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toutefois, MSDS se réserve le droit, sans préavis, ni indemnité, de
            fermer temporairement ou définitivement le site web ou l’accès à un
            ou plusieurs services à distance notamment pour effectuer une mise à
            jour, des opérations de maintenance, des modifications ou
            changements sur les méthodes opérationnelles, les serveurs et les
            heures d’accessibilité, sans que cette liste ne soit limitative.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS n'est pas responsable des dommages de toute nature qui peuvent
            résulter de ces changements et/ou d'une indisponibilité temporaire
            ou encore de la fermeture définitive de tout ou partie du site web
            et/ou des services qui y sont associés.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS se réserve le droit de compléter, de modifier ou de supprimer,
            à tout moment, la plate-forme et/ou un ou plusieurs services qui y
            sont disponibles en fonction notamment de l’évolution de la
            technologie.
          </ArticleTitle>
        </section>
      </article>
      <article>
        <Title number={a++} title="Commande" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Après avoir renseigné ses informations personnelles, le client
            pourra procéder à l’achat d’activités.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client choisit les activités présentées en temps réel sur le site
            web au jour de la commande.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client reconnaît avoir pris connaissance de la nature, de la
            destination et des modalités de commande des activités sur le site
            web et avoir sollicité et obtenu des informations nécessaires et/ou
            complémentaires pour passer sa commande en parfaite connaissance de
            cause.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client est seul responsable de son choix d’activités et de leur
            adéquation à ses besoins, de telle sorte que la responsabilité de
            MSDS ne peut être recherchée à cet égard.{" "}
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toute commande passée par le client suppose une acceptation
            préalable des présentes conditions générales de vente et de ladite
            commande.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toute commande effectuée par le client est réalisée par
            l’intermédiaire de l’interface de commande.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les informations contractuelles feront l’objet d’une confirmation
            par courrier électronique à l’adresse indiquée par le client.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Cette confirmation comprendra notamment les informations suivantes :
            <ul>
              <li>
                une information sur les caractéristiques essentielles des
                activités commandées ;
              </li>
              <li>l’absence de droit de rétractation ;</li>
              <li>l’identification du numéro de la transaction/commande ;</li>
              <li>
                le montant total de la commande (prix H.T., prix T.T.C.) ;
              </li>
              <li>le rappel des conditions de sécurisation du paiement ;</li>
              <li>
                la confirmation des modalités de paiement et d’exécution du
                contrat ;
              </li>
              <li>les limitations de responsabilité éventuelles.</li>
            </ul>
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Il appartient au client, sous sa responsabilité, de conserver sur un
            support de son choix, les informations adressées par MSDS.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS assure la conservation de l’écrit constatant la commande du
            client portant sur un montant supérieur ou égal à cent vingt (120)
            euros pendant une durée de dix (10) ans à compter de la livraison
            ayant fait l’objet de la commande et lui en garantit l’accès à tout
            moment pendant cette même durée.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS prend la pleine responsabilité des transactions qui
            s’effectuent le site internet (Cela inclut les remboursements, le
            droit de rétractation, point de contact en cas de litige etc..)
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les documents seront conservés sous format électronique ou format
            papier.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS garantit l’accès aux documents conservés, sur demande du
            client, selon les modalités suivantes : demande par courrier (au
            siège social de MSDS) ou par courrier électronique à l’adresse
            contact@masalledesport.com.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Droit de rétractation" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Conformément aux dispositions de l’article L 121-20-4 du Code de la
            consommation, le client ne dispose pas de droit de rétractation pour
            l’achat des activités de loisirs.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Livraison" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            La commande des activités fera l’objet d’une confirmation par
            courrier électronique dans lequel seront joints la ou les
            confirmation(s) d’achat(s) relative(s) aux activités commandées sur
            le site web.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Code promotionnel" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Tout utilisateur ou client peut obtenir, dans le cadre d’une
            opération marketing organisée par le site web, ou par le biais d’une
            carte cadeau, un code promotionnel dont les conditions d’utilisation
            sont les suivantes :
            <ul>
              <li>
                il est utilisable par son bénéficiaire en une fois, dans les
                limites de sa durée de validité ;
              </li>
              <li>il est utilisable exclusivement sur le site web ;</li>
              <li>il n’est pas cumulable avec un autre code promotionnel.</li>
            </ul>
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Mode de règlement" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les modes de règlement acceptés sont : paiement par carte bancaire .
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Ces modes de règlement permettent de commander les activités en
            ligne immédiatement de manière ferme. Les cartes acceptées pour le
            paiement d’une commande sont les cartes du réseau Carte Bleue.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} /> Pour tout paiement par carte
            bancaire, la carte bancaire est débitée dès la validation finale de
            la commande.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Sécurisation des paiements" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS a confié son système de paiement à Adyen prestataire spécialisé
            dans la sécurisation des paiements en ligne.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            La totale confidentialité des informations bancaires est garantie.
            Les transactions de plus de 100€ sont sécurisées par le protocole 3D
            Secure qui contrôle systématiquement la validité des droits d'accès
            lors du paiement par carte bancaire et crypte tous les échanges afin
            d'en garantir la confidentialité.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les données personnelles communiquées permettent de mener à bien la
            transaction.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Elles seront enregistrées par MSDS sous réserve de l’accord du
            client matérialisé par un clic sur « J’autorise Adyen à conserver
            mes données bancaires pour le compte de MSDS afin de faciliter les
            prochaines commandes ».
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS pourra demander au client par email ou par téléphone des
            informations complémentaires sur son identité (carte d'identité) et
            l'identité du porteur de la carte bancaire qui a servi au paiement,
            en fonction du montant de la commande et de l'adresse de l'acheteur.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client dispose à tout moment d'un droit d'accès et de
            rectification des données le concernant collectées au moment du
            paiement, à charge pour lui d’en faire la demande par courrier (au
            siège social de MSDS) ou par courrier électronique à l’adresse
            contact@masalledesport.com.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Prix" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les prix afférents à la commande des activités sont indiqués sur le
            site web.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les prix sont affichés en euros, en montant TTC (toutes taxes
            comprises).
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toutes les commandes, quelle que soit leur origine, sont payables en
            euros.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les prix tiennent compte de la TVA applicable au jour de la commande
            et tout changement du taux applicable à la TVA sera automatiquement
            répercuté sur les prix indiqués sur le site web.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Lors de la confirmation de la commande du client, le montant total
            de la commande sera indiqué, (prix TTC), en euros.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Facturation" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client peut demander l'envoi d'une facture à l'adresse de
            livraison indiquée sur la confirmation d’achat ou à toute autre
            adresse de son choix.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Service client" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Pour toute information ou réclamation, le service clientèle est
            disponible aux conditions figurant sur le site web.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Données à caractère personnel" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS fait son affaire des formalités lui incombant au titre de la
            réglementation relative à la protection des données à caractère
            personnel, en particulier de la loi n°78-17 du 6 janvier 1978
            relative à l'informatique, aux fichiers et aux libertés.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS garantit le respect des obligations légales et réglementaires
            lui incombant au titre de la protection des données à caractère
            personnel, notamment en matière de flux transfrontières hors de
            l’Union européenne.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            En application de la loi Informatique et libertés du 6 janvier 1978,
            les personnes physiques dont les données à caractère personnel sont
            traitées peuvent faire l’objet d’un droit de questionnement,
            d’accès, de modification et de rectification par la demande par
            courrier (au siège social de MSDS) ou par courrier électronique à
            l’adresse contact@masalledesport.com, à défaut de précisions
            particulières figurant sur les documents de collecte de données à
            caractère personnel. Elles disposent également d’un droit
            d’opposition, pour motif légitime, et d’un droit d’opposition à la
            prospection notamment commerciale.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les cookies enregistrent certaines informations qui sont stockées
            dans la mémoire du disque des utilisateurs.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            En aucun cas les cookies ne contiennent d'informations
            confidentielles, telles que le nom ou le numéro de carte bancaire,
            les cookies permettent de garder en mémoire les articles
            sélectionnés lors de précédentes visite ainsi que les pages sur
            lesquelles l'utilisateur ou client a navigué.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Responsabilité" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            La responsabilité de MSDS ne saurait être engagée en raison des
            nuisances ou dommages inhérents à l’usage du réseau internet, tels
            que rupture de service, intrusion extérieure, présence de virus
            informatiques ou tout fait qualifié de force majeure par les
            tribunaux.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les photographies et les textes reproduits et illustrant les
            activités présentés ne sont pas contractuels. En conséquence, la
            responsabilité de MSDS ne saurait être engagée en cas d'erreur dans
            l'une de ces photographies ou l'un de ces textes.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS ne saurait être tenu pour responsable de l'inexécution d’un
            contrat en cas de force majeure, du fait d’un tiers ou du fait du
            client.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS ne pourra en aucun cas, même si sa responsabilité était
            engagée, être tenue d’indemniser des dommages indirects.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            MSDS n’assumera aucune responsabilité dans le cas où le contenu des
            sites auxquels les utilisateurs du site web ou les clients
            pourraient être renvoyés par des liens hypertextes, contreviendrait
            aux dispositions légales et réglementaires en vigueur.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Propriété intellectuelle" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les éléments appartenant à MSDS tels que la plate-forme, les
            marques, les dessins, les modèles, les images, les textes, les
            photos, les logos, les chartes graphiques, les logiciels, les
            moteurs de recherche, les bases de données, sans que cette liste ne
            soit exhaustive, sont sa propriété exclusive.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les présentes conditions générales de vente n’emportent aucune
            cession d’aucune sorte de droits de propriété intellectuelle sur
            tout ou partie des éléments appartenant à MSDS
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Toute reproduction et/ou représentation, totale ou partielle d’un de
            ces éléments, sans l’autorisation expresse de MSDS est interdite et
            constituerait une contrefaçon sanctionnée par les articles L. 335-2
            et suivants du Code de la propriété intellectuelle.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            En conséquence, le client s'interdit tout agissement et tout acte
            susceptible de porter atteinte directement ou non aux droits de
            propriété intellectuelle de MSDS
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les éléments appartenant à des tiers, telle que marques, dessins,
            modèles, images, textes, photos, logo sans que cette liste ne soit
            exhaustive, sont la propriété exclusive de leur auteur ou de leur
            déposant, et sont protégés à ce titre par le droit d’auteur, le
            droit des marques ou tout autre droit reconnu par la législation en
            vigueur.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le client s'engage à respecter l'intégralité des droits de tiers,
            dont les contenus sont présents sur la plate-forme et s'interdit de
            susciter toute analogie dans l'esprit du public à quelque fin que ce
            soit.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />A cet effet, le client s'engage à
            prendre toutes les mesures nécessaires à la protection desdits
            droits à l'égard de tous tiers et, notamment, maintiendra en état
            toutes les mentions de propriété qui seront portées sur l'ensemble
            des données, informations et plus généralement sur les éléments
            consultables sur la plate-forme ou rendus accessibles par des tiers.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Preuve et convention de preuve" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Le double clic réalisé lors de la validation de la commande
            constitue une signature électronique qui a, entre les parties, la
            même valeur qu’une signature manuscrite.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les registres informatisés, conservés dans les systèmes
            informatiques de MSDS dans des conditions raisonnables de sécurité,
            constituent les preuves des communications, des commandes et des
            paiements intervenus entre les parties. Elles font foi jusqu’à
            preuve du contraire.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            L’archivage des commandes et factures est effectué sur un support
            fiable et durable pouvant être produit à titre de preuve.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Bonne foi" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les parties conviennent d’exécuter leurs obligations avec une
            parfaite bonne foi.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Titres" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            En cas de difficultés d’interprétation résultant d’une contradiction
            entre l’un quelconque des titres figurant en tête des clauses et
            l’une quelconque des clauses, les titres seront déclarés
            inexistants.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Nullité" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Si une ou plusieurs stipulations du présent contrat sont tenues pour
            non valides ou déclarées comme telles en application d’une loi, d’un
            règlement ou à la suite d’une décision passée en force de chose
            jugée d’une juridiction compétente, les autres stipulations
            garderont toute leur force et leur portée.
          </ArticleTitle>
        </section>
      </article>

      <article>
        <Title number={a++} title="Loi applicable" />
        <section>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Les présentes conditions générales de vente sont régies par la loi
            française.
          </ArticleTitle>
          <ArticleTitle>
            <SectionNumber number={s++} />
            Il en est ainsi pour les règles de fond et les règles de forme et
            ce, nonobstant les lieux d’exécution des obligations substantielles
            ou accessoires.
          </ArticleTitle>
        </section>
      </article>
    </div>
  );
};
