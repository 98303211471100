import { StyledLabel, RquiredLabel } from "./styles/Form.style";

export default function Label({ errors, title, htmlFor, required }) {
  return (
    <StyledLabel htmlFor={htmlFor} errors={errors}>
      {title}
      {required && <RquiredLabel>*</RquiredLabel>}
    </StyledLabel>
  );
}
