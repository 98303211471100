import { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  AboWrapper,
  AboHeader,
  TitleLink,
  ToggleAbo,
  ExpandIcon,
  DescriptionItem,
  ChangeAboLink,
  AboPromo,
  AboFooter,
  AboDescription,
} from "./styles/AbosChoices.style";

function OpenAbo({
  currentAbo,
  theme,
  abo: a,
  club,

  query,
}) {
  const [openAbo, setOpenAbo] = useState(false);
  return (
    <>
      <AboHeader>
        <TitleLink
          selected={currentAbo}
          color={theme.primary}
          to={`${club.id}?${query}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={currentAbo ? theme.primary : "#555"}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            {currentAbo && <circle cx="12" cy="12" r="5" />}
          </svg>
          <h1>
            Dès {(a.monthlyPrice / 100).toFixed(2)}€/{a.monthlyLabel}&nbsp;
            <small>-&nbsp;{a.name}</small>
          </h1>
        </TitleLink>

        <ToggleAbo type="button" onClick={() => setOpenAbo(!openAbo)}>
          <ExpandIcon
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={currentAbo ? theme.primary : "#555"}
            openAbo={openAbo}
          >
            <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
          </ExpandIcon>
        </ToggleAbo>
      </AboHeader>

      <AboDescription openAbo={openAbo}>
        <AboPromo currentAbo={currentAbo} color={theme.promoColor}>
          <h2>Engagement {a.duration} mois</h2>
          <strong>{a.promo.actual.title}</strong>
          <p>{a.promo.actual.description}</p>
        </AboPromo>

        <ul>
          {a.description.map((d) => (
            <DescriptionItem key={d}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill={currentAbo ? theme.primary : "#555"}
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
              </svg>
              {d}
            </DescriptionItem>
          ))}
        </ul>

        <AboFooter currentAbo={currentAbo} color={theme.primary}>
          {a.fees && (
            <h3>
              {a.fees.amount / 100}€<small>{a.fees.label}</small>
            </h3>
          )}
          <h3>
            {(a.promo.actual.funDisplay[0].price / 100).toFixed(2)}/{a.monthlyLabel}
            <small>{a.promo.actual.funDisplay[0].display}</small>
          </h3>

          <ChangeAboLink
            selected={currentAbo}
            color={theme.primary}
            to={`${club.id}?${query.toString()}`}
          >
            {currentAbo ? "Formule choisie" : "Changer"}
          </ChangeAboLink>
        </AboFooter>
      </AboDescription>
    </>
  );
}

export default function AbosChoices({ abos, club, selected, theme }) {
  const search = useLocation().search;

  return abos.map((a) => {
    const currentAbo = a === selected;
    const query = new URLSearchParams(search);
    query.set("selectedType", "abo");
    query.set("selectedId", a.id);

    return (
      <AboWrapper key={a.id}>
        <OpenAbo
          club={club}
          abo={a}
          currentAbo={currentAbo}
          theme={theme}
          query={query.toString()}
        />
      </AboWrapper>
    );
  });
}
