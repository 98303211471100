import { StyledLoader } from "./styles/Loader.style";

export default function Loader() {
  return (
    <StyledLoader>
      <div>
        <div />
      </div>
    </StyledLoader>
  );
}
