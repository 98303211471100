import styled from "styled-components";

export const FormWrapper = styled.section`
  @media (min-width: 1290px) {
    margin-top: 1rem;
  }
`;

export const StyledInput = styled.input`
  border-color: ${({ errors }) => (errors ? "#EF4444" : "#cbd5e1")};
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;

  &:focus {
    outline: none;
    border-color: ${({ errors, colors }) =>
      errors ? "#EF4444" : colors ? colors : "#7e2e84"};
  }
`;

export const Checkbox = styled.input`
  margin: 0 0.5rem 0 0;
`;

export const StyledLabel = styled.label`
  color: ${({ errors }) => (errors ? "#EF4444" : "")};
  display: block;
  margin-bottom: 0.25rem;
  transition: all 0.2s ease-in-out;
`;

export const RquiredLabel = styled.span`
  color: #ef4444;
`;

export const ChekboxLabel = styled.label`
  color: ${({ errors }) => (errors ? "#EF4444" : "")};
  font-size: 0.75rem;

  a {
    color: inherit;
  }
`;

export const InvalidInput = styled.p`
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  animation: fadeInError 0.2s ease-in-out;

  @keyframes fadeInError {
    from {
      opacity: 0;
      transform: translateY(-0.25rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;

  .PhoneInputInput {
    padding: 0.5rem;
    border-radius: 0.375rem;
    border-color: ${({ errors }) => (errors ? "#EF4444" : "#cbd5e1")};
    border-width: 1px;
    border-style: solid;

    &:focus {
      outline: none;
      border-color: ${({ errors, colors }) =>
        errors ? "#EF4444" : colors ? colors : "#7e2e84"};
    }
  }
`;

export const StepButton = styled.button`
  appearance: none;
  border: ${({ colors }) =>
    colors ? `2px solid ${colors}` : "2px solid #ff005d"};
  background-color: ${({ full }) => (full ? "#ff005d" : "transparent")};
  color: ${({ full, colors }) => (colors ? colors : full ? "#fff" : "#ff005d")};
  width: ${({ small }) => (small ? "10rem" : "100%")};
  text-transform: uppercase;
  padding: 1rem 2rem;
  font-weight: 600;
  border-radius: 9999px;
  transition: all 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;

  &:disabled {
    border: 2px solid #a1a1aa;
    background-color: ${({ full }) => (full ? "#a1a1aa" : "transparent")};
    color: ${({ full }) => (full ? "#fff" : "#a1a1aa")};
    cursor: default;

    &:hover {
      background-color: inherit;
      color: ${({ full }) => (full ? "#fff" : "#a1a1aa")};
    }
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 1290px) {
    width: auto;
    cursor: pointer;

    &:hover {
      background-color: ${({ full, colors }) =>
        colors ? colors : full ? "transparent" : "#ff005d"};
      color: #fff;
    }
  }
`;

export const Select = styled.select`
  appearance: none;
  background-color: transparent;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgba(9, 25, 21, 0.32) 50%
    ),
    linear-gradient(135deg, rgba(9, 25, 21, 0.32) 50%, transparent 50%);
  background-position: calc(100% - 1.25rem) calc(1rem + 0.025rem),
    calc(100% - 0.9375rem) calc(1rem + 0.025rem), calc(100% - 2.5rem) 0.5rem;
  background-size: 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 0.0625rem 1.5rem;
  background-repeat: no-repeat;
  border-color: ${({ errors }) => (errors ? "#EF4444" : "#cbd5e1")};
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;

  &:last-child {
    margin-left: 0.5rem;
  }
  &:first-child {
    margin-right: 0.5rem;
  }

  &:focus {
    outline: none;
    border-color: ${({ errors }) => (errors ? "#EF4444" : "#7e2e84")};
  }
`;

export const StepButtonWrapper = styled.div`
  @media (min-width: 1290px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledForm = styled.form`
  @media (min-width: 1290px) {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
    position: sticky;
    top: 6rem;
  }
`;

export const CgvModaleOverlay = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 11;
  display: ${({ openModale }) => (openModale ? "block" : "none")};
`;

export const CgvModale = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 1.5rem 1rem;
  z-index: 12;
  overflow-y: auto;
  height: calc(100vh - 3rem);
  display: ${({ openModale }) => (openModale ? "block" : "none")};

  @media (min-width: 1290px) {
    top: 50%;
    left: 50%;
    max-height: 90vh;
    transform: translate(-50%, -50%);
  }

  div {
    margin-top: 1rem;
  }

  p {
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 600;
  }
`;

export const CloseModale = styled.button`
  height: 2rem;
  width: 2rem;
  background-color: transparent;
  border: none;
  appearance: none;
  font-size: 0;
  float: right;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #171717;
    display: block;
  }

  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

export const StepTitle = styled.h3`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2rem;
`;
