import moment from "moment";

import Lock from "./assets/icons/lock.svg";
import Visa from "./assets/icons/visa.svg";
import Mastercard from "./assets/icons/mastercard.svg";
import {
  StickyFooter,
  SecureFooter,
  SecureImgWrapper,
  SecureTitle,
  SecureImg,
  MainFooter,
  Nav,
  NavWrapper,
  NavTitle,
  LinksWrapper,
  Links,
  Legals,
} from "./styles/Footer.style";

function Footer() {

  return (
    <StickyFooter>
      <SecureFooter>
        <SecureTitle>
          <img src={Lock} alt="" /> Transactions sécurisées
        </SecureTitle>
        <SecureImgWrapper>
          <SecureImg height="48" src={Visa} alt="Visa" />
          <SecureImg height="40" src={Mastercard} alt="Mastercard" />
        </SecureImgWrapper>
      </SecureFooter>

      <MainFooter>
        <article>
          <Nav>
            <NavWrapper>
              <li>
                <NavTitle>Responsable de club</NavTitle>
              </li>
              <LinksWrapper>
                <Links href="https://www.masalledesport.com/referencement">
                  Référencer mon club de sport
                </Links>
              </LinksWrapper>
              <LinksWrapper>
                <Links href="https://pro.masalledesport.com/clubs/dashboard">
                  Accès PRO
                </Links>
              </LinksWrapper>
            </NavWrapper>
            <NavWrapper>
              <li>
                <NavTitle>En savoir plus</NavTitle>
              </li>
              <LinksWrapper>
                <Links href="https://www.masalledesport.com/contact">
                  Contact
                </Links>
              </LinksWrapper>
              <LinksWrapper>
                <Links href="https://www.masalledesport.com/mentions">
                  Mentions légales
                </Links>
              </LinksWrapper>
            </NavWrapper>
          </Nav>
        </article>

        <article>
          <Legals>
            {moment().format("YYYY")} © MaSalleDeSport.com
            <br />
            Droits de reproduction et de diffusion réservés.
            <br />
            MaSalleDeSport.com est une marque déposée.
          </Legals>
        </article>
      </MainFooter>
    </StickyFooter>
  );
}

export default Footer;
