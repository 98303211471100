import styled from "styled-components";

import ShadowBottom from "../assets/patterns/shadow-bottom.svg";
import Slash from "../assets/patterns/slash.svg";
import Disc from "../assets/patterns/disc.svg";
import Dots from "../assets/patterns/dots.svg";

export const StickyFooter = styled.footer`
  grid-row-start: 2;
  grid-row-end: 3;
`;

export const SecureFooter = styled.section`
  padding: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  background-color: #f8fafc;
`;

export const SecureImgWrapper = styled.article`
  margin: 2rem 0 1rem;
`;

export const SecureTitle = styled.strong`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const SecureImg = styled.img`
  &:first-child {
    margin-right: 1.5rem;
  }
`;

export const MainFooter = styled.section`
  padding: 5rem 1.25rem;
  background-color: #1e1f20;
  color: #fff;
  background-image: url(${ShadowBottom}), url(${Slash}), url(${Disc}),
    url(${Dots});
  background-position: bottom, bottom -25rem right 65%, top -5rem left 10%,
    top 30% right 10%;
  background-size: cover, auto, 15rem, 10rem;
  background-repeat: no-repeat;
`;

export const Nav = styled.nav`
  display: grid;
  margin: 1rem 0;

  @media (min-width: 1290px) {
    grid-template-columns: 2fr 1fr 5fr;
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const NavWrapper = styled.ul`
  margin-bottom: 3rem;
`;

export const NavTitle = styled.strong`
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  margin-bottom: 1.5rem;
`;

export const LinksWrapper = styled.li`
  margin-bottom: 0.5rem;
`;

export const Links = styled.a`
  color: #fff;
  text-decoration: none;
  line-height: 1.5;
`;

export const Legals = styled.p`
  font-size: 0.75rem;
  line-height: 1;
  opacity: 0.7;

  @media (min-width: 1290px) {
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AboFooterWrapper = styled.section`
  text-align: center;
  color: #fff;
  background-color: #494949;
  padding: 1.5rem;
`;

export const AboSecureText = styled.p`
  text-transform: uppercase;
  margin-top: 0.5rem;

  strong {
    font-weight: 600;
  }
`;

export const AboFooterLegals = styled.p`
  font-size: 0.75rem;
  text-align: center;
  color: #fff;
  background-color: #3c3c3c;
  padding: 1.5rem;
`;
