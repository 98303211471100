const thisYear = new Date().getFullYear();
export const yearList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
  (i) => thisYear + i
);

export const monthList = [
  {
    id: "01",
    name: "Janvier",
  },
  {
    id: "02",
    name: "Février",
  },
  {
    id: "03",
    name: "Mars",
  },
  {
    id: "04",
    name: "Avril",
  },
  {
    id: "05",
    name: "mai",
  },
  {
    id: "06",
    name: "Juin",
  },
  {
    id: "07",
    name: "Juillet",
  },
  {
    id: "08",
    name: "Aout",
  },
  {
    id: "09",
    name: "Septembre",
  },
  {
    id: "10",
    name: "Octobre",
  },
  {
    id: "11",
    name: "Novembre",
  },
  {
    id: "12",
    name: "Décembre",
  },
];
