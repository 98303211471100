import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Choices from "./Choices";
import { useCurrentClub } from "./CurrentClub";
import { Main, PaymentStep } from "./styles/Layout.style";
import { StepButton } from "./styles/Form.style";
import InfosForm from "./InfosForm";
import SepaForm from "./SepaForm";
import CBForm from "./CBForm";
import ThemeProvider from "./ThemeProvider";
import Loader from "./Loader";

import apiPass from "./ApiPass";

const Adyen = require("adyen-cse-js");

export default function Layout() {
  const location = useLocation();
  const [club, clubs] = useCurrentClub();
  const [selected, setSelected] = useState(false);
  const { abo: abos, pass } = club.shop;
  const [paying, payingSet] = useState(false);
  const [step, stepSet] = useState({ id: "USER" });
  const [userInfo, userInfoSet] = useState(loadClientFromStorage());
  const [cardInfo, cardInfoSet] = useState({});
  const [sepaInfo, sepaInfoSet] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedType = params.get("selectedType");
    const selectedId = params.get("selectedId");
    if (selectedType === "abo") {
      abos &&
        abos.forEach((a) => {
          if (a.id === +selectedId) {
            setSelected(a);
          }
        });
    }

    if (selectedType === "pass") {
      pass &&
        pass.forEach((p) => {
          if (p.id === +selectedId) {
            setSelected(p);
          }
        });
    }
  }, [abos, pass, location.search]);

  const createLead = () => {
    if (window.origin.indexOf("localhost") !== -1) {
      console.warn("No abo/vel lead when on localhost");
      return;
    }

    if (!selected) {
      return null;
    }

    let apiCall = apiPass.leadVelCreate;
    if (selected.type === "abo") {
      apiCall = apiPass.leadAboCreate;
    }

    apiCall({
      source: location.search.indexOf("fromMsds") === -1 ? 'MSDS_EXTERN_REFERER' : 'MSDS',
      source_url: window.location.href,
      client: {
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        email: userInfo.email,
        phone: userInfo.phone,
      },
      place: {
        id: club.id,
      },
    }).catch(console.error);
  };

  const stepNext = () => {
    if (step.id === "USER") {
      createLead();

      stepSet({ id: "PAYMENT" });
      return;
    }
    if (step.id === "PAYMENT") {
      //No next
      return;
    }
  };

  const stepBack = () => {
    if (step.id === "USER") {
      //NO PREVIOUS
      return;
    }
    if (step.id === "PAYMENT") {
      stepSet({ id: "USER" });
      return;
    }
  };

  const retryPayment = () => {
    payingSet(false);
  };

  const onSubmitInfos = (client) => {
    saveClientToStorage(client);
    userInfoSet(client);
    stepNext();
  };

  const onCardInfoSubmit = (card) => {
    cardInfoSet(card);
    doBuyPass({ user: userInfo, card: card });
  };

  const onSepaInfoSubmit = (sepa) => {
    sepaInfoSet(sepa);
    doBuyAbo({ user: userInfo, sepa: sepa });
  };

  const doBuyPass = ({ user, card }) => {
    payingSet(true);

    // See adyen.encrypt.nodom.html for details
    let cseInstance = Adyen.createEncryption(process.env.REACT_APP_ADYEN_KEY, {
      enableValidations: false,
    });

    let encrypted = cseInstance.encrypt({
      number: card.number.trim(),
      cvc: card.cvc.trim(),
      holderName: card.ccname.trim(),
      expiryMonth: card.ccmonth.trim(),
      expiryYear: card.ccyear.trim(),
      generationtime: new Date().toISOString(),
    });

    let formData = {
      source: location.search.indexOf("fromMsds") === -1 ? 'MSDS_EXTERN_REFERER' : 'MSDS',
      source_url: window.location.href,
      client: {
        firstname: user.firstname.trim(),
        lastname: user.lastname.trim(),
        email: user.email.trim(),
        phone: user.phone.trim(),
      },
      card: {
        partialHidden: "**** **** **** " + card.number.trim().slice(-4),
        encrypted: encrypted,
      },
    };

    apiPass
      .buyPass(selected.id, formData)
      .then(() => {
        payingSet("SUCCESS");
      })
      .catch((e) => {
        if (e.restCode === "paymentFailed") {
          payingSet("Vérifiez les informations de votre carte");
        } else {
          payingSet("Une erreur est survenue");
        }
      });
  };

  const doBuyAbo = ({ user, sepa }) => {
    payingSet(true);
    const query = new URLSearchParams(location.search);
    let selectedOptions = (query.get("aboOptions") || "")
      .split("-")
      .map((oid) => +oid)
      .filter((oid) => {
        return !!oid;
      });

    const params = {
      client: {
        firstname: user.firstname.trim(),
        lastname: user.lastname.trim(),
        email: user.email.trim(),
        phone: user.phone.trim(),
      },
      place: {
        id: club.id,
      },
      plan: {
        id: selected.id,
        modification: {
          date: selected.modification.date,
        },
        promo: {
          actual: {
            type: selected.promo.actual.type,
          },
        },
      },
      options: selectedOptions.map((oid) => ({ id: oid })),
      paymentMethod: {
        owner: sepa.name,
        rib: sepa.rib,
      },
    };

    apiPass
      .buyAbo(params)
      .then(() => {
        payingSet("SUCCESS");
      })
      .catch((e) => {
        const errMsg = e.userMsg || "Une erreur est survenue";
        payingSet(errMsg);
      });
  };

  const withHeader = (children) => {
    return (
      <ThemeProvider>
        <div>
          <Header />
          <Main>{children}</Main>
        </div>
        <Footer />
      </ThemeProvider>
    );
  };

  if (paying === true) {
    return (
      <PaymentStep>
        <Loader />
        <h1>Paiement en cours</h1>
      </PaymentStep>
    );
  }

  if (paying === "SUCCESS") {
    // TODO Message
    return (
      <PaymentStep>
        <h1>Votre paiement a bien été effectué.</h1>
      </PaymentStep>
    );
  }

  if (paying !== false) {
    return (
      <PaymentStep>
        <h1>Le paiement a échoué</h1>
        <p>{paying}</p>
        <StepButton type="button" onClick={retryPayment}>
          Réessayer
        </StepButton>
      </PaymentStep>
    );
  }

  if (step.id === "USER") {
    return withHeader(
      <>
        <Choices club={club} clubs={clubs} selected={selected} />
        {selected && (
          <InfosForm defaultValues={userInfo} onSubmit={onSubmitInfos} />
        )}
      </>
    );
  }

  if (step.id === "PAYMENT") {
    if (selected.type === "pass") {
      return withHeader(
        <>
          <Choices club={club} clubs={clubs} selected={selected} />
          <CBForm
            offer={selected}
            onSubmit={onCardInfoSubmit}
            onBack={stepBack}
            defaultValues={cardInfo}
          />
        </>
      );
    } else if (selected.type === "abo") {
      return withHeader(
        <>
          <Choices club={club} clubs={clubs} selected={selected} />
          <SepaForm
            offer={selected}
            onSubmit={onSepaInfoSubmit}
            onBack={stepBack}
            defaultValues={sepaInfo}
            club={club}
          />
        </>
      );
    } else {
      console.error("Cannot pay selected", selected);
      return null;
    }
  }

  console.error("Unknow step, id=", step.id);
  return null;
}

function loadClientFromStorage() {
  try {
    return JSON.parse(localStorage.getItem("msds-client-info")) || {};
  } catch (e) {
    console.error("Cannot load from localStorage", e);
  }
}

function saveClientToStorage(data) {
  const client = {
    firstname: data.firstname.trim(),
    lastname: data.lastname.trim(),
    email: data.email.trim(),
    phone: data.phone.trim(),
  };
  try {
    localStorage.setItem("msds-client-info", JSON.stringify(client));
  } catch (e) {
    console.error("Cannot store to localStorage", e);
  }
}
