import Label from "./Label";
import Input from "./Input";
import { useForm } from "react-hook-form";

import { validEmail } from "./utils/Regex";
import PhoneInput from "./PhoneInput";
import {
  StepButton,
  StepButtonWrapper,
  FormGroup,
  InvalidInput,
  StyledForm,
  FormWrapper,
  StepTitle,
} from "./styles/Form.style";
import { useTheme } from "./ThemeProvider";

function InfosForm({ onSubmit, defaultValues = {} }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const theme = useTheme();

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StepTitle>Mes Informations personnelles</StepTitle>
        <FormGroup>
          <Label
            errors={errors.firstname}
            htmlFor="firstname"
            title="Prénom"
            required
          />
          <Input
            {...register("firstname", {
              required: true,
            })}
            aria-invalid={errors.firstname ? "true" : "false"}
            id="firstname"
            type="text"
            name="firstname"
            errors={errors}
            colors={theme.secondary}
          />
          {errors.firstname && errors.firstname.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            errors={errors.lastname}
            htmlFor="lastname"
            title="Nom"
            required
          />
          <Input
            {...register("lastname", {
              required: true,
            })}
            aria-invalid={errors.lastname ? "true" : "false"}
            id="lastname"
            type="text"
            name="lastname"
            errors={errors}
            colors={theme.secondary}
          />
          {errors.lastname && errors.lastname.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label errors={errors.email} htmlFor="email" title="Email" required />
          <Input
            id="email"
            type="email"
            name="email"
            errors={errors}
            colors={theme.secondary}
            {...register("email", {
              required: {
                value: true,
              },
              pattern: {
                value: validEmail,
              },
            })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email && errors.email.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
          {errors.email && errors.email.type === "pattern" && (
            <InvalidInput>Adresse email non valide</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            errors={errors.phone}
            htmlFor="phone"
            title="Téléphone"
            required
          />
          <PhoneInput
            name="phone"
            value={defaultValues.phone || ""}
            control={control}
            errors={errors}
            colors={theme.secondary}
          />
          {errors.phone && errors.phone.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>

        <StepButtonWrapper>
          <StepButton
            colors={theme.primary}
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Continuer
          </StepButton>
        </StepButtonWrapper>
      </StyledForm>
    </FormWrapper>
  );
}

export default InfosForm;
