import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTheme } from "./ThemeProvider";
import Label from "./Label";
import Input from "./Input";
import {
  FormGroup,
  InvalidInput,
  Checkbox,
  ChekboxLabel,
  StyledForm,
  StepButton,
  CgvModale,
  CgvModaleOverlay,
  CloseModale,
  FormWrapper,
  StepTitle,
} from "./styles/Form.style";
import { FlexWrapper } from "./styles/Layout.style";

export default function SepaForm({ defaultValues, onBack, club, onSubmit }) {
  const theme = useTheme();
  const [openModale, setOpenModale] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", openModale);

    const close = (e) => {
      if (e.keyCode === 27) {
        document.body.classList.remove("overflow-hidden", openModale);
        setOpenModale(!openModale);
      }
    };

    window.addEventListener("keydown", close);
    return () => {
      document.body.classList.remove("overflow-hidden", openModale);
      window.removeEventListener("keydown", close);
    };
  }, [openModale]);

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StepTitle>Mes Informations de paiement</StepTitle>
        <FormGroup>
          <Label
            htmlFor="name"
            errors={errors.name}
            title="Titulaire du compte"
            required
          />
          <Input
            {...register("name", {
              required: true,
            })}
            aria-invalid={errors.name ? "true" : "false"}
            id="name"
            type="text"
            name="name"
            errors={errors}
          />
          {errors.name && errors.name.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            htmlFor="rib"
            errors={errors.rib}
            title="Numero Iban"
            required
          />
          <Input
            {...register("rib", {
              required: true,
            })}
            aria-invalid={errors.rib ? "true" : "false"}
            id="rib"
            type="text"
            name="rib"
            errors={errors}
          />
          {errors.rib && errors.rib.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup isFlex>
          <FlexWrapper alignItems="center">
            <Checkbox
              {...register("cgv", {
                required: true,
              })}
              aria-invalid={errors.cgv ? "true" : "false"}
              id="cgv"
              type="checkbox"
              name="cgv"
              errors={errors}
            />
            <ChekboxLabel htmlFor="cgv" errors={errors.cgv}>
              <button
                type="button"
                style={{
                  display: "inline",
                  background: "transparent",
                  border: "none",
                  appearance: "none",
                  textAlign: "left",
                  textDecoration: "underline",
                  cursor: "pointer",
                  outline: "none",
                }}
                onClick={() => setOpenModale(!openModale)}
              >
                J'ai lu et accepté les conditions générales de vente et le
                règlement intérieur de la salle et de MaSalleDeSport
              </button>
            </ChekboxLabel>
          </FlexWrapper>
          {errors.cgv && errors.cgv.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FlexWrapper justifyContent="space-between">
          <StepButton
            small
            colors={theme.primary}
            type="button"
            onClick={onBack}
          >
            Retour
          </StepButton>
          <StepButton
            colors={theme.primary}
            small
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Continuer
          </StepButton>
        </FlexWrapper>
      </StyledForm>
      <ModaleCgv
        openModale={openModale}
        setOpenModale={setOpenModale}
        theme={theme}
        cgv={club.shop.config.cgv}
      />
    </FormWrapper>
  );
}

function ModaleCgv({ openModale, setOpenModale, cgv, theme }) {
  return ReactDOM.createPortal(
    <>
      <CgvModaleOverlay
        onClick={() => setOpenModale(!openModale)}
        openModale={openModale}
      />
      <CgvModale openModale={openModale}>
        <CloseModale type="button" onClick={() => setOpenModale(!openModale)}>
          Fermer
        </CloseModale>
        <div dangerouslySetInnerHTML={{ __html: cgv }} />
      </CgvModale>
    </>,
    document.getElementById("root")
  );
}
