import Label from "./Label";
import Input from "./Input";
import { useForm } from "react-hook-form";

import { yearList, monthList } from "./utils/cbExpiration";
import {
  FormGroup,
  InvalidInput,
  Checkbox,
  ChekboxLabel,
  Select,
  StyledForm,
  StepButton,
  FormWrapper,
  StepTitle,
} from "./styles/Form.style";
import { FlexWrapper } from "./styles/Layout.style";
import CardCCV from "./assets/icons/card-cvc.png";
import { useTheme } from "./ThemeProvider";

export default function CBForm({ onSubmit, onBack, defaultValues }) {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)} id="adyen-encrypted-form">
        <StepTitle>Mes Informations bancaires</StepTitle>
        <input
          type="hidden"
          id="adyen-Generationtime"
          value="generate-this-server-side"
          data-encrypted-name="generationtime"
        />
        <FormGroup>
          <Label
            errors={errors.ccname}
            htmlFor="ccname"
            title="Titulaire de la carte"
            required
          />
          <Input
            {...register("ccname", {
              required: true,
            })}
            aria-invalid={errors.ccname ? "true" : "false"}
            id="ccname"
            type="text"
            name="ccname"
            errors={errors}
            data-encrypted-name="holderName"
          />
          {errors.ccname && errors.ccname.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            errors={errors.number}
            htmlFor="number"
            title="Numéro de la carte"
            required
          />
          <Input
            {...register("number", {
              required: true,
            })}
            aria-invalid={errors.number ? "true" : "false"}
            id="number"
            type="text"
            name="number"
            errors={errors}
            data-encrypted-name="number"
          />
          {errors.number && errors.number.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label
            errors={errors.ccmonth}
            htmlFor="ccmonth"
            title="Expiration"
            required
          />
          <FlexWrapper>
            <Select
              name="ccmonth"
              id="ccmonth"
              {...register("ccmonth", {
                required: true,
              })}
              data-encrypted-name="expiryMonth"
            >
              <option value="">Mois</option>
              {monthList.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.id}-{m.name}
                </option>
              ))}
            </Select>
            <Select
              name="ccyear"
              id="ccyear"
              {...register("ccyear", {
                required: true,
              })}
              data-encrypted-name="expiryYear"
            >
              <option value="">Année</option>
              {yearList.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </Select>
          </FlexWrapper>
          {errors.ccmonth && errors.ccmonth.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
          {errors.ccyear && errors.ccyear.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>
        <FormGroup>
          <Label errors={errors.cvc} htmlFor="cvc" title="Code CV" required />
          <FlexWrapper>
            <img style={{ marginRight: "1rem" }} src={CardCCV} alt="" />
            <Input
              {...register("cvc", {
                required: true,
                maxLength: 3,
              })}
              aria-invalid={errors.cvc ? "true" : "false"}
              id="cvc"
              type="number"
              name="cvc"
              errors={errors}
              data-encrypted-name="cvc"
            />
          </FlexWrapper>
          {errors.cvc && errors.cvc.type === "required" && (
            <InvalidInput>Ce champ est obligatoire.</InvalidInput>
          )}
        </FormGroup>
        <FormGroup isFlex>
          <FlexWrapper alignItems="center">
            <Checkbox
              {...register("cgv", {
                required: true,
              })}
              aria-invalid={errors.cgv ? "true" : "false"}
              id="cgv"
              type="checkbox"
              name="cgv"
              errors={errors}
            />
            <ChekboxLabel htmlFor="cgv" errors={errors.cgv}>
              J'ai lu et accepté les&nbsp;
              <a target="_blank" rel="noreferrer" href="/cgv">
                conditions générales de vente
              </a>
            </ChekboxLabel>
          </FlexWrapper>
          {errors.cgv && errors.cgv.type === "required" && (
            <InvalidInput>Ce champ est obligatoire</InvalidInput>
          )}
        </FormGroup>

        <FlexWrapper justifyContent="space-between">
          <StepButton
            small
            colors={theme.primary}
            type="button"
            onClick={onBack}
          >
            Retour
          </StepButton>
          <StepButton
            colors={theme.primary}
            small
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Continuer
          </StepButton>
        </FlexWrapper>
      </StyledForm>
    </FormWrapper>
  );
}
