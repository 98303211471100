import styled from "styled-components";

export const MainHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  background-color: ${({ headerBg }) => headerBg || "#ffffff"};
  height: 4rem;
  z-index: 10;
  box-shadow: 0.5rem 0 1rem rgba(30, 31, 32, 0.1);

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1290px) {
    display: block;

    div {
      justify-content: flex-start;
    }
  }
`;

export const Logo = styled.svg`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const CustomLogo = styled.img`
  max-height: 3rem;
  margin-right: 0.5rem;
`;

export const Title = styled.strong`
  font-weight: 600;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  @media (min-width: 1290px) {
    display: flex;
    align-items: center;
    height: 4rem;
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
  }
`;
