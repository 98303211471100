import Header from "./Header";
import Footer from "./Footer";

import { Main, ErrorTitle, BackLink } from "./styles/ErrorPage.style";

function Error() {
  return (
    <>
      <div>
        <Header />
        <Main>
          <ErrorTitle>Une erreur est survenue</ErrorTitle>
          <p>Il semble que vous n'ayez suivi aucun lien d'offre.</p>
          <p>
            Rendez-vous sur{" "}
            <BackLink href="https://www.masalledesport.com/">
              MaSalleDeSport.com
            </BackLink>
          </p>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default Error;
