function request(path, method, _body, _headers, {multipart=false} ={} ) {

  const headers = _headers || {};
  if ( multipart ) {
  } else {
    headers['content-type'] = 'application/json';
  }

  let body = _body;

  if ( body && !multipart) {
    body = JSON.stringify(_body);
  }

  const fullPath = window.location.origin + path;

  return fetch(fullPath, { body, headers, method })
  .then((response) => {
    if (!response) Promise.reject(null);

    if (!response.ok) {
      if (response.status === 401) {
        window.location.href = `https://auth.masalledesport.com/login?returnUrl=${encodeURIComponent(window.location.href)}`;
        return Promise.reject(null);
      }
      return response.json().then((json) => {
        return Promise.reject(json)
        .catch( e => {
          console.error(`[baseApi] Cannot parse json response from ${method} ${path}, status:${response.status}`, e);
          throw e;
        });
      });
    }

    if (response.status === 204) return null;

    return response.json()
    .catch( e => {
      console.error(`[baseApi] Cannot parse json response from ${method} ${path}, status:${response.status}`, e);
      throw e;
    });
  });
}

const Api = {
  DELETE: (path, body, headers, options) => request(path, 'DELETE', body, headers, options),
  GET: (path, body, headers, options) => request(path, 'GET', body, headers, options),
  PATCH: (path, body, headers, options) => request(path, 'PATCH', body, headers, options),
  POST: (path, body, headers, options) => request(path, 'POST', body, headers, options),
  PUT: (path, body, headers, options) => request(path, 'PUT', body, headers, options)
};

export default Api;
