import { Link } from "react-router-dom";
import styled from "styled-components";

export const AboWrapper = styled.article`
  border-bottom: 1px solid #cbd5e1;
  padding-top: 1.5rem;
`;

export const AboHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const TitleLink = styled(Link)`
  color: ${({ selected, color }) => (selected ? color : "inherit")};
  text-decoration: none;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.1s ease-in-out;

  small {
    font-weight: normal;
    font-size: initial;
  }

  svg {
    margin-right: 1rem;
    transition: all 0.1s ease-in-out;
  }
`;

export const ToggleAbo = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
`;

export const ExpandIcon = styled.svg`
  transform: ${({ openAbo }) => (openAbo ? "rotate(180deg)" : "")};
  transition: all 0.2s ease-in-out;
`;

export const DescriptionItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  font-size: 0.875rem;

  svg {
    margin-right: 1rem;
    transition: all 0.1s ease-in-out;
  }
`;

export const ChangeAboLink = styled(Link)`
  color: ${({ selected, color }) => (selected ? color : "inherit")};
  text-decoration: none;
  display: block;
  margin: 2.5rem 0 1.5rem 0;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
`;

export const AboPromo = styled.div`
  margin-left: 2.5rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-row-gap: 0.75rem;

  h2 {
    font-weight: 600;
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.25rem;
  }

  strong {
    color: ${({ currentAbo, color }) => (currentAbo ? color : "")};
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    color: ${({ currentAbo, color }) => (currentAbo ? color : "")};
  }
`;

export const AboFooter = styled.footer`
  margin-top: 2rem;
  margin-left: 2.5rem;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    color: ${({ currentAbo, color }) => (currentAbo ? color : "")};

    small {
      font-weight: normal;
      font-size: 0.875rem;
      color: initial;
      margin-left: 0.5rem;
    }

    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const AboDescription = styled.section`
  height: ${({ openAbo }) => (openAbo ? "auto" : "0")};
  opacity: ${({ openAbo }) => (openAbo ? "1" : "0")};
  visibility: ${({ openAbo }) => (openAbo ? "visible" : "hidden")};
  transform: ${({ openAbo }) =>
    openAbo ? "translateY(0)" : "translateY(-1vh)"};
  transition: ${({ openAbo }) => (openAbo ? "all 0.2s ease-in-out" : "")};
  transition-delay: ${({ openAbo }) => (openAbo ? "0.1s" : "")};
`;
