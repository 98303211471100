import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";

import {Link} from 'react-router-dom';

import {  StepButton, } from "./styles/Form.style";

import { useTheme } from "./ThemeProvider";
import AbosChoices from "./AbosChoices";
import PassChoices from "./PassChoices";
import {
  ClubName,
  ClubAddress,
  SkewSeparator,
  OfferTitle,
  NoChoiceSelected,
  ChoicesContainer,
  HomeLink,
  OptionInfos,
  SingleOption,
  OptionPrice,
  OptionSelect,
  OptionsWrapper,
  OptionsMoreInfos,
} from "./styles/Choices.style";

export default function Choices({ club, clubs, selected }) {
  const theme = useTheme();
  const search = useLocation().search;
  const { secondary, primary } = theme;
  const {
    shop: { pass },
    address: { street, street2, zip, city },
  } = club;
  const abos = club.shop.abo;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const link = `/clubs?${urlSearchParams.toString()}`;

  return (
    <ChoicesContainer>
      <header>
        <ClubName colors={secondary}>{club.name}</ClubName>
        <ClubAddress colors={secondary}>
          {street}, <br />
          {street2}
          {street2 && <br />}
          {zip}&nbsp;{city}
          <a href={`tel:${club.tel}`}>{club.tel}</a>
          {clubs.length > 1 && <Link to={link}><StepButton style={{fontSize:'0.8rem', margin:"0.7rem",padding:'0.5rem', maxWidth:'10rem'}}>Changez de club</StepButton></Link>}
        </ClubAddress>

        <SkewSeparator color={primary} />
      </header>

      {!abos.length && !pass.length && (
        <p>
          Ce club ne propose aucune offre en ligne.
          <br />
          Rendez-vous sur{" "}
          <HomeLink href="https://www.masalledesport.com/">
            Ma Salle de Sport
          </HomeLink>
        </p>
      )}

      {!!pass.length && (
        <>
          <OfferTitle>Pass sans engagement</OfferTitle>
          {!selected && (
            <NoChoiceSelected>Veuillez sélectionner un pass</NoChoiceSelected>
          )}
          <PassChoices
            pass={pass}
            search={search}
            selected={selected}
            club={club}
            theme={theme}
          />
        </>
      )}

      {!!abos.length && (
        <>
          <OfferTitle margin={!!pass.length}>Abonnement</OfferTitle>
          {!selected && (
            <NoChoiceSelected>
              Veuillez sélectionner un Abonnement
            </NoChoiceSelected>
          )}
          <AbosChoices
            abos={abos}
            club={club}
            selected={selected}
            theme={theme}
          />
          {/*Options are the same for every abo in the same club... so we can do it like that abos[0].options */}
          {!!abos[0].options.length && selected && selected.type === "abo" && (
            <OptionChoices
              theme={theme}
              clubId={club.id}
              options={abos[0].options}
            />
          )}
        </>
      )}
    </ChoicesContainer>
  );
}

function OptionChoices({ clubId, options, theme }) {
  const { search } = useLocation();

  return (
    <OptionsWrapper>
      <h3>Options de l'abonnement</h3>
      <ul>
        {options.map((o) => {
          const query = new URLSearchParams(search);

          let selectedOptions = (query.get("aboOptions") || "")
            .split("-")
            .map((oid) => +oid)
            .filter((oid) => {
              return !!oid && options.some((o) => o.id === oid);
            });
          const selected = selectedOptions.indexOf(o.id) !== -1;

          if (selected) {
            selectedOptions = selectedOptions.filter((oid) => oid !== o.id);
            if (selectedOptions.length) {
              query.set("aboOptions", selectedOptions.join("-"));
            } else {
              query.delete("aboOptions");
            }
          } else {
            selectedOptions.push(o.id);
            query.set("aboOptions", selectedOptions.join("-"));
          }
          const changeUrl = `${clubId}?${query.toString()}`;

          return (
            <Fragment key={o.id}>
              <SingleOptionWrapper
                theme={theme}
                selected={selected}
                changeUrl={changeUrl}
                o={o}
              />
            </Fragment>
          );
        })}
      </ul>
    </OptionsWrapper>
  );
}

function SingleOptionWrapper({ theme, selected, changeUrl, o }) {
  const [openInfos, setOpenInfos] = useState(false);
  return (
    <>
      <li>
        <SingleOption theme={theme} selected={selected}>
          <OptionSelect selected={selected} theme={theme} to={changeUrl}>
            {selected ? "Selectionné" : "Non selectionné"}
          </OptionSelect>
          <p>{o.name}</p>
          <OptionsMoreInfos onClick={() => setOpenInfos(!openInfos)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 0 24 24"
              width="20px"
              fill={selected ? theme.primary : theme.secondary}
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
          </OptionsMoreInfos>

          <OptionPrice>{o.price / 100}€/{o.monthlyLabel}</OptionPrice>
        </SingleOption>
      </li>
      <OptionInfos color={theme.secondary} openInfos={openInfos}>
        {o.description}
      </OptionInfos>
    </>
  );
}
