import ThemeProvider from "./ThemeProvider";
import Header from "./Header";
import Footer from "./Footer";
import {Link} from 'react-router-dom';

 import {  StepButton, } from "./styles/Form.style";

import {
  ClubName,
  ClubAddress,
  SkewSeparator,
  ChoicesWrapper
} from "./styles/Choices.style";
import { useTheme } from "./ThemeProvider";

export default function ClubSelector({clubs}) {
  if( !clubs )return null;

  const firstConfigured = clubs && clubs.find(c => c?.shop?.config);

  return (
    <ThemeProvider forceTheme={firstConfigured.shop.config}>
      <div>
        <Header />
        <div style={{padding:'1rem'}}>
          <ChoicesWrapper>
            {clubs.map(c => {
              return <ClubChoice key={c.id} club={c} />
            })}
          </ChoicesWrapper>
        </div>
        
      </div>
      <Footer />
    </ThemeProvider>
  );
}

function ClubChoice({club}) {
  const theme = useTheme();
  const { secondary, primary } = theme;
  const {
    address: { street, street2, zip, city },
  } = club;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const link = `/club/${club.id}?${urlSearchParams.toString()}`;

  return (
    <article>
      {!!club.images.length && <img src={club.images[0].url} alt={club.name} />}
      <div>
        <ClubName colors={secondary}>{club.name}</ClubName>
        <ClubAddress colors={secondary}>
          {street}, <br />
          {street2}
          {street2 && <br />}
          {zip}&nbsp;{city}
        </ClubAddress>
        <Link to={link}><StepButton style={{fontSize:'0.8rem', margin:"0.7rem 0",padding:'0.5rem', maxWidth:'10rem'}}>Choisir ce club</StepButton></Link>
        <SkewSeparator color={primary} />
      </div>
    </article>
  );
}