import { useState } from "react";

import {
  PassHeader,
  PassLink,
  TogglePass,
  ExpandIcon,
  PassDescription,
  PassContainer,
  ChangePassLink,
} from "./styles/PassChoices.style";

function PassOpen({ currentPass, club, query, theme, pass: p }) {
  const [openPass, setOpenPass] = useState(false);

  return (
    <PassContainer>
      <PassHeader>
        <PassLink
          to={`${club.id}?${query.toString()}`}
          color={theme.primary}
          selected={currentPass}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={currentPass ? theme.primary : "#555"}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            {currentPass && <circle cx="12" cy="12" r="5" />}
          </svg>
          <h1>
            {p.price}€&nbsp;
            <small>
              -{p.reduction}%&nbsp;&nbsp;{p.name}
            </small>
          </h1>
        </PassLink>

        <TogglePass type="button" onClick={() => setOpenPass(!openPass)}>
          <ExpandIcon
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={currentPass ? theme.primary : "#555"}
            openPass={openPass}
          >
            <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
          </ExpandIcon>
        </TogglePass>
      </PassHeader>

      <PassDescription openPass={openPass}>
        <p>{p.detail}</p>
        <ChangePassLink
          selected={currentPass}
          color={theme.primary}
          to={`${club.id}?${query.toString()}`}
        >
          {currentPass ? "Pass choisi" : "Changer"}
        </ChangePassLink>
      </PassDescription>
    </PassContainer>
  );
}

export default function PassChoices({ pass, search, selected, club, theme }) {
  return pass.map((p) => {
    const query = new URLSearchParams(search);
    query.set("selectedType", "pass");
    query.set("selectedId", p.id);

    const currentPass = p === selected;

    return (
      <PassOpen
        key={p.id}
        currentPass={currentPass}
        club={club}
        query={query}
        theme={theme}
        pass={p}
      />
    );
  });
}
