import React, { useState, useEffect, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

import Loader from "./Loader";
import { useCurrentClub } from "./CurrentClub";

const Context = createContext();

export const useTheme = () => useContext(Context);
export default ThemeProvider;

function ThemeProvider({ forceTheme, children }) {
  const location = useLocation();
  const [theme, themeSet] = useState(false);
  const [currentClub] = useCurrentClub();

  useEffect(() => {
    themeSet(false);
    if (!currentClub && !forceTheme) return;

    let configToUse = null;
    if (forceTheme ){
      configToUse = forceTheme
    } else {
      configToUse = currentClub?.shop?.config;
    }
    let theme = {
      header_bg: "#fff",
      logoPath: `${window.origin}/msds.svg`,
      promoColor: "#7e2e84",
      primary: "#ff005d",
      secondary: "#171717",
      title: "Ma Salle De Sport",
      cgv: "",
    };

    if (location.search.indexOf("fromMsds") === -1) {
      theme = {
        header_bg:
          configToUse?.colors_header_bg || theme.header_bg,
        logoPath: configToUse?.logo_path || theme.logoPath,
        primary: configToUse?.colors_primary || theme.primary,
        secondary:
          configToUse?.colors_secondary || theme.secondary,
        promoColor: configToUse?.colors_promo || theme.promoColor,
        cgv: configToUse?.cgv || theme.cgv,
      };
    }
    themeSet(theme);
  }, [forceTheme, currentClub, location.search]);
  if (theme === false) return <Loader />;

  return <Context.Provider value={theme}>{children}</Context.Provider>;
}
