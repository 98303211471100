import apiBase from "./ApiBase";

const ApiPass = {
  buyPass : (productId, params) => {
    return apiBase.POST(`/api/shop/product/${productId}/sales`, params);
  },
  buyAbo : (params) => {
    return apiBase.POST('/api/abo/abo', params);

  },
  leadVelCreate: params => apiBase.POST('/api/shop/lead', params),
  leadAboCreate: params => apiBase.POST('/api/abo/lead', params),
};

export default ApiPass;
