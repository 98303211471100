import Header from "./Header";
import Footer from "./Footer";

import { Main, ErrorTitle, BackLink } from "./styles/ErrorPage.style";

export default function Landing() {
  return (
    <>
      <div>
        <Header />
        <Main>
          <ErrorTitle>
            Bienvenue sur la page de vente en ligne Ma Salle de Sport
          </ErrorTitle>

          <p>
            Rendez-vous sur{" "}
            <BackLink href="https://www.masalledesport.com/">
              MaSalleDeSport.com
            </BackLink>{" "}
            pour découvrir toutes les offres.
          </p>
        </Main>
      </div>
      <Footer />
    </>
  );
}
