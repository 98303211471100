import { Link } from "react-router-dom";
import styled from "styled-components";

export const PassContainer = styled.article`
  padding-top: 1.5rem;
  border-bottom: 1px solid #cbd5e1;
`;

export const PassHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const PassLink = styled(Link)`
  color: ${({ selected, color }) => (selected ? color : "inherit")};
  text-decoration: none;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.1s ease-in-out;

  small {
    font-weight: normal;
    font-size: initial;
    margin-left: 1rem;
  }

  svg {
    margin-right: 1rem;
    transition: all 0.1s ease-in-out;
  }
`;

export const TogglePass = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
`;

export const ExpandIcon = styled.svg`
  transform: ${({ openPass }) => (openPass ? "rotate(180deg)" : "")};
  transition: all 0.2s ease-in-out;
`;

export const PassDescription = styled.section`
  height: ${({ openPass }) => (openPass ? "auto" : "0")};
  opacity: ${({ openPass }) => (openPass ? "1" : "0")};
  visibility: ${({ openPass }) => (openPass ? "visible" : "hidden")};
  transform: ${({ openPass }) =>
    openPass ? "translateY(0)" : "translateY(-1vh)"};
  transition: ${({ openPass }) => (openPass ? "all 0.2s ease-in-out" : "")};
  transition-delay: ${({ openPass }) => (openPass ? "0.1s" : "")};

  p {
    margin: 1.5rem 0 0 2.5rem;
    line-height: 1.5;
  }
`;

export const ChangePassLink = styled(Link)`
  color: ${({ selected, color }) => (selected ? color : "inherit")};
  text-decoration: none;
  display: block;
  margin: 1.5rem 0;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
`;
