import styled from "styled-components";

export const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  div {
    height: 0.25rem;
    overflow: hidden;
    position: absolute;
    width: 10rem;
    background-color: rgba(203, 215, 225, 0.4);

    margin: 0;
    top: 50%;
    transform: translateX(-50%) skew(-24deg);

    div {
      animation: animateLoader 2s cubic-bezier(0.26, 0.75, 0.98, 0.67) infinite;
      background-color: #ff005d;
      height: 0.25rem;
      position: absolute;
      top: 0;
      width: 50%;
    }
  }

  @keyframes animateLoader {
    0%,
    100% {
      transform: skew(-24deg) translateX(-50%);
    }
    50% {
      transform: skew(-24deg) translateX(150%);
    }
  }
`;
