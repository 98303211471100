import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Landing from "./Landing";
import CurrentClub from "./CurrentClub";
import Layout from "./Layout";
import ErrorPage from "./ErrorPage";
import Cgv from "./cgv";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/clubs" component={ClubSelect} />
        <Route exact path="/club/:clubId" component={Main} />
        <Route exact path="/cgv" component={Cgv} />
        <Route exact path="/error" component={ErrorPage} />
        <Redirect from="*" to="/error" />
      </Switch>
    </Router>
  );
}

export default App;

function Main({ match }) {
  const { clubId } = match.params;

  return (
    <CurrentClub clubId={clubId}>
      <Layout />
    </CurrentClub>
  );
}


function ClubSelect() {
  return (
    <CurrentClub clubId={false}>
      <Layout />
    </CurrentClub>
  );
}
